body {
    margin:0;
    padding:0;
}
.row {
    margin: 0;
}
div[class^="col-"] {
    padding:0;
}
*:focus {
    outline: none;
}
